import styled from "@xstyled/styled-components";
import { useNavigate } from "react-router-dom";

import { Onboarding } from "../Auth/Onboarding";
import { useStripeCheckoutLink } from "../JobsList/JobSlots/useStripeCheckoutLink";

import { useRequireLoginState } from "./useRequireLoginState";
import { BreadCrumbs } from "./Breadcrumbs";

import { pxToRem } from "@otta/design-tokens";
import { useAnswers, useProgress } from "@hire/components/layout/QuizLayout";
import { Loading } from "@otta/shared-components";
import { Text } from "@otta/design";
import { useNewStarterJourney } from "@hire/hooks/useNewStarterJourney";

const PageContainer = styled.div`
  width: 100%;
  margin: 0 0 1rem 0;
`;

const noop = () => null;

export function Signup(): React.ReactElement | null {
  useProgress(0);
  const [{ companyName, email, sectorTags, shortDescription, websiteUrl }] =
    useAnswers();

  const { callback, loading: stripeLoading } =
    useStripeCheckoutLink("quiz/success");

  const navigate = useNavigate();
  const newStarterJourneyActive = useNewStarterJourney();

  const { loading: loginStateLoading } = useRequireLoginState({
    state: "logged-out",
    redirect: "/quiz/success",
  });

  if (stripeLoading || loginStateLoading) {
    return <Loading />;
  } else {
    return (
      <PageContainer>
        <BreadCrumbs currentBreadcrumb="Account" />
        <Onboarding
          title="Create an account"
          email={email}
          companyName={companyName}
          shortDescription={shortDescription}
          sectorTags={sectorTags}
          websiteUrl={websiteUrl}
          onSuccess={() => {
            newStarterJourneyActive ? noop() : callback();
          }}
          onError={(error: string) => {
            if (
              newStarterJourneyActive &&
              error.includes("short_description")
            ) {
              navigate("/quiz/company", { state: { error: error } });
            }
          }}
          backgroundColor="transparent"
        />
        <Text size={-1} style={{ margin: `0 ${pxToRem(24)}` }}>
          <Text size={-1} inline bold>
            No commitment.
          </Text>{" "}
          Pay monthly, cancel online anytime.
        </Text>
      </PageContainer>
    );
  }
}
