import { CanIntegrate } from "./CanIntegrate";
import { CantIntegrate } from "./CantIntegrate";
import { NotFound } from "./NotFound";
import { KomboIntegration } from "./KomboIntegration";

import { useAuthorization } from "@hire/providers/authorization";
import { CompanyAtsApiKeyTypeEnum, Feature } from "@hire/schema";

export function NoIntegration({
  atsName,
  atsAuthorizeUrl,
  atsApiKeys,
  scraperUrl,
  companyId,
  refetch,
}: {
  atsName: string | null;
  atsAuthorizeUrl: string | null;
  atsApiKeys: { type: CompanyAtsApiKeyTypeEnum }[];
  scraperUrl: string | null;
  companyId: string;
  refetch(): Promise<unknown>;
}) {
  const { granted: komboGranted } = useAuthorization(
    Feature.KomboAtsIntegration
  );

  if (atsName && typeof atsAuthorizeUrl === "string") {
    return <CanIntegrate atsAuthorizeUrl={atsAuthorizeUrl} atsName={atsName} />;
  }

  if (atsName && komboGranted && typeof scraperUrl === "string") {
    return (
      <KomboIntegration
        atsName={atsName}
        atsApiKeys={atsApiKeys}
        scraperUrl={scraperUrl}
        companyId={companyId}
        refetch={refetch}
      />
    );
  }

  if (typeof scraperUrl === "string") {
    return <CantIntegrate scraperUrl={scraperUrl} />;
  }

  return <NotFound />;
}
