import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { PricingModal } from "./PricingModal";

import { JobInfoDocument, JobWorkflowStatus } from "@hire/schema";
import { useCurrentUser } from "@hire/hooks/useCurrentUser";
import { useRequiredParams } from "@hire/util/routing";

export function Pricing() {
  const { jobExternalId, companySlug } = useRequiredParams([
    "jobExternalId",
    "companySlug",
  ]);

  const { data: jobData, loading: jobLoading } = useQuery(JobInfoDocument, {
    variables: { jobId: jobExternalId as string },
  });

  const [user, { loading: jobSlotsLoading }] = useCurrentUser();
  const company = user?.currentCompany;
  const paidSlots = company?.jobSlotData?.paid;
  const navigate = useNavigate();

  useEffect(() => {
    if ((paidSlots ?? 0) > 0) {
      navigate(`../unfreeze-your-role/${jobExternalId}`);
    }
  }, [navigate, paidSlots, jobExternalId]);

  if (!jobExternalId || jobLoading || !jobData?.companyJob || jobSlotsLoading) {
    return null;
  }

  const returnPath =
    jobData.companyJob.workflowStatus === JobWorkflowStatus.CompletedByUser
      ? `${companySlug}/jobs/successful-payment/${jobExternalId}`
      : `${companySlug}/checkout/confirmation`;

  return <PricingModal job={jobData.companyJob} returnPath={returnPath} />;
}
