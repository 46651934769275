import styled from "@xstyled/styled-components";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

import { useJobSlotStripeCheckoutLink } from "../useJobSlotStripeCheckoutLink";
import { JobSlotsModal } from "../JobSlotsModal";

import { Button, Heading, Text } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import {
  CatalogueProductId,
  JobFragment,
  ProductQuoteDocument,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { formatCurrency } from "@hire/util/currencies";
import { useRequiredParams } from "@hire/util/routing";

const IconContainer = styled.div`
  display: inline;
  margin-right: sm;
`;

const ButtonContainer = styled.div`
  margin-top: xl;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const LineItem = styled(Text).attrs({ as: "div" })`
  display: flex;
`;

const StyledButton = styled(Button)`
  width: 50%;
`;

export const PricingModal = ({
  job,
  returnPath,
}: {
  job: Pick<JobFragment, "id" | "title" | "externalId">;
  returnPath: string;
}) => {
  const { data, loading } = useQuery(ProductQuoteDocument, {
    variables: {
      quantity: 1,
      productId: CatalogueProductId.JobSlotMonthly,
    },
  });

  const { companySlug } = useRequiredParams(["companySlug"]);

  const currency = data?.catalogue.product?.quote.currency;
  const subTotal = data?.catalogue.product?.quote.lineItems[0].netSubtotal;

  const { callback, loading: stripeLoading } = useJobSlotStripeCheckoutLink(
    job.id,
    returnPath
  );

  const formattedCurrency =
    subTotal && currency && formatCurrency(subTotal, currency);

  const checkpoints = [
    "Pay today to start hiring immediately.",
    "No commitment, cancel online anytime.",
    "Reuse this job slot for other jobs.",
    "Subscription will continue until explicitly cancelled, which can be done online.",
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <JobSlotsModal name="pricing" redirectOnClose={`${companySlug}/jobs`}>
      <Text size={2} bold>
        Publish your {job.title} role
      </Text>

      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          margin: `${pxToRem(24)} 0`,
        }}
      >
        <Heading size={3}>{formattedCurrency}</Heading>
        <Text bold size={1} style={{ marginLeft: pxToRem(8) }}>
          per month (excluding VAT)
        </Text>
      </div>

      {checkpoints.map((checkpoint, idx) => (
        <LineItem key={idx}>
          <IconContainer>
            <Icon icon="checkmark" size={1.5} />
          </IconContainer>
          {checkpoint}
        </LineItem>
      ))}

      <ButtonContainer>
        <StyledButton
          level="primary"
          disabled={stripeLoading}
          onClick={() => callback()}
        >
          {stripeLoading ? "Loading" : "Continue to payment"}
        </StyledButton>
        <StyledButton
          level="destructive"
          as={Link}
          to="../why-otta"
          state={{ redirect: `../pricing/${job.externalId}` }}
        >
          Maybe later
        </StyledButton>
      </ButtonContainer>
    </JobSlotsModal>
  );
};
