import { Link as RouterLink } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { Icon } from "@otta/icons";
import { pxToRem } from "@otta/design-tokens";
import { Middle, Spacing, Text, Button, Card } from "@otta/design";
import { Link } from "@hire/components/links";

const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 75 lg lg;
  position: relative;
  padding-top: 45;
`;

const ButtonWrapper = styled.div`
  padding-top: lg;
`;

const Logo = styled.img`
  width: 100%;
  border-radius: 4;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 100%;
  padding: 15;
  width: ${pxToRem(75)};
  height: ${pxToRem(75)};
  z-index: 1;
`;

export function RequiresActivation({
  companyName,
  faviconPath,
}: {
  companyName: string;
  faviconPath?: string;
}): React.ReactElement {
  return (
    <Middle maxWidth={600}>
      <Wrapper>
        <IconWrapper>
          {faviconPath ? (
            <Logo src={faviconPath} alt={`${companyName} logo`} />
          ) : (
            <Icon icon="settings" size={4} />
          )}
        </IconWrapper>
        <Spacing>
          <Text bold as="h2" size={3}>
            We&apos;re getting you set up
          </Text>
          <Text>
            Thanks for requesting to join the <b>{companyName}</b> account on
            Welcome to the Jungle.
          </Text>
          <Text>You should receive an email soon with next steps.</Text>
          <Text>
            If you don&apos;t receive an email, contact{" "}
            <Link to="mailto:companies@welcometothejungle.com">
              companies@welcometothejungle.com
            </Link>
            .
          </Text>
          <ButtonWrapper>
            <Button
              data-testid="logout-button"
              level="primary"
              as={RouterLink}
              to="/logout"
            >
              Log out
            </Button>
          </ButtonWrapper>
        </Spacing>
      </Wrapper>
    </Middle>
  );
}
