import styled from "@xstyled/styled-components";

import { ResetATSKeys } from "./ResetATSKeys";

import { Feature } from "@hire/schema";
import { pxToRem } from "@otta/design-tokens";
import { Button, Card, Spacing, Text } from "@otta/design";
import { Link } from "@hire/components/links/Link";
import { useAuthorization } from "@hire/providers/authorization";

const CardWrapper = styled.div`
  max-width: ${pxToRem(720)};
`;

export const ATSConnected = ({
  atsName,
  atsAuthorizeUrl,
  showResetKeys,
  companyId,
}: {
  atsName: string;
  atsAuthorizeUrl: string | null;
  showResetKeys: boolean;
  companyId: string;
}): React.ReactElement => {
  const { granted } = useAuthorization(Feature.AtsIntegration);

  return (
    <CardWrapper>
      <Spacing size={3}>
        {granted && (
          <Card>
            <Spacing size={1}>
              <Text align="left" data-testid="export-sourced-candidates">
                We have connected with {atsName} and are automatically adding
                your jobs.
              </Text>
              <Text align="left">
                If you want to add new roles, upload them to {atsName} and they
                will be published within 1 working day.
              </Text>
              <Text align="left">
                <Button
                  style={{ textDecoration: "none" }}
                  level="primary"
                  as={Link}
                  to="../../jobs"
                >
                  View jobs
                </Button>
              </Text>
            </Spacing>
          </Card>
        )}

        <Card>
          <Spacing size={1}>
            <Text align="left">
              If your ATS has changed and is no longer {atsName}, complete this
              form to let us know. We will automatically add your jobs from your
              new ATS.
            </Text>
            <Text align="left">
              <Button
                style={{ textDecoration: "none" }}
                level="secondary"
                as={Link}
                to="https://form.typeform.com/to/Lxg4Qa0T"
              >
                Complete form
              </Button>
            </Text>
          </Spacing>
        </Card>

        {atsAuthorizeUrl && (
          <Card>
            <Text align="left">
              Need to update your integration? Click{" "}
              <Link data-testid="ats-update-integration" to={atsAuthorizeUrl}>
                here
              </Link>{" "}
              to re-integrate.
            </Text>
          </Card>
        )}
        {showResetKeys && (
          <Card>
            <Spacing size={2}>
              <Text align="left">
                You can reset your {atsName} API keys to reset your integration.
                You may need to do this to resolve security issues, for example
                when employees leave the company, or because your ATS has
                recommended this.
              </Text>
              <Text align="left">
                <ResetATSKeys companyId={companyId} />
              </Text>
            </Spacing>
          </Card>
        )}
      </Spacing>
    </CardWrapper>
  );
};
