import styled from "@xstyled/styled-components";
import { Link, useParams } from "react-router-dom";

import { JobSlotsModal } from "../JobSlotsModal";

import { Button, Card, Text } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";

const IconContainer = styled.div`
  width: ${pxToRem(24)};
  display: inline;
  margin-right: sm;
`;

const ButtonContainer = styled.div`
  margin-top: xl;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1em;
`;

const LineItem = styled(Text)<{ hasMargin?: boolean }>`
  display: flex;
  align-items: center;
  margin: ${({ hasMargin }) => hasMargin && `lg 0`};
`;

const StyledButton = styled(Button)`
  width: 50%;
`;

export const AddAJob = () => {
  const { companySlug } = useParams<"companySlug">();

  return (
    <JobSlotsModal name="add-a-job" redirectOnClose={`/${companySlug}/jobs`}>
      <Text size={2} bold style={{ marginBottom: pxToRem(24) }}>
        Hire on Welcome to the Jungle with job slots
      </Text>
      <Card style={{ backgroundColor: palette.beige.shade200 }}>
        <LineItem>
          <IconContainer>
            <Icon icon="play-pause" size={2} />
          </IconContainer>
          <div>
            <Text bold inline>
              {`No commitment. `}
            </Text>
            Pay monthly, cancel online anytime.
          </div>
        </LineItem>

        <LineItem hasMargin>
          <IconContainer>
            <Icon icon="salary" size={2} />
          </IconContainer>
          <div>
            <Text bold inline>
              {`Pay for what you need. `}
            </Text>
            Flex up and down with your hiring plans.
          </div>
        </LineItem>

        <LineItem>
          <IconContainer>
            <Icon icon="users" size={2} />
          </IconContainer>
          <div>
            <Text bold inline>
              {`Over 2 million candidates. `}
            </Text>
            An application sent every 3 seconds.
          </div>
        </LineItem>
      </Card>

      <Text>
        To{" "}
        <Text bold inline>
          post a new job
        </Text>
        , you need to buy a job slot.
      </Text>
      <ButtonContainer>
        <StyledButton
          level="primary"
          as={Link}
          to={`/${companySlug}/jobs/add-a-job-pricing`}
        >
          Continue to pricing
        </StyledButton>
        <StyledButton
          level="destructive"
          as={Link}
          to="../why-otta"
          state={{ redirect: `/${companySlug}/jobs/add-a-job-pricing` }}
        >
          Maybe later
        </StyledButton>
      </ButtonContainer>
    </JobSlotsModal>
  );
};
