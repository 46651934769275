import { showKomboConnect } from "@kombo-api/connect";
import Cookies from "js-cookie";
import styled, { css, up } from "@xstyled/styled-components";

import { ResetATSKeys } from "./ResetATSKeys";

import { Spacing, Button, Text, Middle, Card } from "@otta/design";
import { Link } from "@hire/components/links/Link";
import { CompanyAtsApiKeyTypeEnum } from "@hire/schema";

const handleClick = async (refetch: () => Promise<unknown>) => {
  const link = await getKomboConnectLink();
  const activationToken = await showKomboConnect(link);
  await activateKomboIntegration(activationToken);
  refetch();
};

const getKomboConnectLink = async () => {
  const response = await fetch(
    `${import.meta.env.VITE_API_HOST}/integrations/kombo/init`,
    {
      method: "POST",
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error("Something went wrong");
  }

  const data = await response.json();
  return data.link;
};

const activateKomboIntegration = async (token: string) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_HOST}/integrations/kombo/activate`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Cookies.get(import.meta.env.VITE_CSRF_COOKIE) as string,
      },
      body: JSON.stringify({ token: token }),
    }
  );

  if (!response.ok) {
    throw new Error("Something went wrong");
  }
};

const BenefitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: xxs;
  flex-basis: 100%;
`;

const BenefitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: md;

  ${up(
    "tablet",
    css`
      gap: xl;
      flex-direction: row;
    `
  )}
`;

export function KomboIntegration({
  scraperUrl,
  atsApiKeys,
  atsName,
  companyId,
  refetch,
}: {
  scraperUrl: string;
  atsApiKeys: { type: CompanyAtsApiKeyTypeEnum }[];
  atsName: string;
  companyId: string;
  refetch(): Promise<unknown>;
}): React.ReactElement {
  return (
    <Middle textAlign="left">
      <Spacing size={4}>
        <Card>
          <Spacing size={1}>
            <Text data-testid="scraping-roles-link">
              We are currently adding your jobs from the following page:
            </Text>
            <Text>
              <Link to={scraperUrl} newTab>
                <Button level="primary">View page</Button>
              </Link>
            </Text>
            <Text>
              If you want to add new jobs, upload them to your ATS and they will
              be published within 1 working day.
            </Text>
            <Text>
              If your ATS has changed,{" "}
              <Link newTab to="https://form.typeform.com/to/Lxg4Qa0T">
                complete this form to let us know
              </Link>
              .
            </Text>
          </Spacing>
        </Card>
        <Card>
          <Spacing size={1}>
            {atsApiKeys && atsApiKeys.length > 0 ? (
              <Spacing size={1}>
                <Spacing>
                  <Text size={1} bold>
                    {atsName} integration
                  </Text>
                  <Text>
                    You can reset your {atsName} API keys to reset your
                    integration. You may need to do this to resolve security
                    issues, for example when employees leave the company, or
                    because your ATS has recommended this.
                  </Text>
                  <Text>
                    <ResetATSKeys companyId={companyId} />
                  </Text>
                </Spacing>
              </Spacing>
            ) : (
              <Middle>
                <Spacing size={4}>
                  <Spacing>
                    <Text size={1} bold>
                      Good news! Welcome to the Jungle is now compatible with{" "}
                      {atsName}
                    </Text>
                    <Text>Linking {atsName} is quick and easy</Text>
                    <Button
                      level="primary"
                      onClick={() => handleClick(refetch)}
                    >
                      Get started
                    </Button>
                  </Spacing>
                  <BenefitsWrapper>
                    <BenefitWrapper>
                      <Text bold>
                        View and manage applications in {atsName}
                      </Text>
                      <Text size={-1}>
                        Candidate CVs will appear in your ATS and will contain
                        their answers to application questions
                      </Text>
                    </BenefitWrapper>
                    <BenefitWrapper>
                      <Text bold>Automatically keep jobs up to date</Text>
                      <Text size={-1}>
                        The quickest and most reliable way to get your jobs
                        published on Welcome to the Jungle
                      </Text>
                    </BenefitWrapper>
                    <BenefitWrapper>
                      <Text bold>Export candidates to {atsName}</Text>
                      <Text size={-1}>
                        Easily export candidates sourced on Welcome to the
                        Jungle in just one click
                      </Text>
                    </BenefitWrapper>
                  </BenefitsWrapper>
                  <Spacing size={3}>
                    <Text size={-1}>
                      Need help getting set up?{" "}
                      <Link to="mailto:help@welcometothejungle.com">
                        <Text
                          as="span"
                          size={-1}
                          style={{ textDecoration: "underline" }}
                        >
                          Contact us
                        </Text>
                      </Link>
                      .
                    </Text>
                  </Spacing>
                </Spacing>
              </Middle>
            )}
          </Spacing>
        </Card>
      </Spacing>
    </Middle>
  );
}
