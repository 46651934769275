import { GroupBase } from "react-select";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import { IOption } from "@hire/components/form/JobEditForm/types";
import { JobWorkflowStatus, JobsListDocument } from "@hire/schema";

// isn't JS a beautiful language
export function useJobOptions(): {
  data: GroupBase<IOption>[];
  loading: boolean;
} {
  const { data, loading } = useQuery(JobsListDocument, {
    variables: {
      locations: [],
      workflowStatuses: [
        JobWorkflowStatus.Archived,
        JobWorkflowStatus.Completed,
        JobWorkflowStatus.Changed,
        JobWorkflowStatus.CompletedByUser,
      ],
    },
  });

  return useMemo(() => {
    const byFunction: Record<string, IOption[]> = {};

    const jobList =
      data?.me?.__typename === "CurrentCompanyRecruiter" ||
      data?.me?.__typename === "CurrentAdmin"
        ? data.me.currentCompany?.listJobs ?? []
        : [];

    if (!jobList && loading) {
      return { data: [], loading: true };
    }

    for (const job of jobList) {
      const jobFunc = job.jobFunction?.value ?? "Other";
      if (!byFunction[jobFunc]) {
        byFunction[jobFunc] = [];
      }
      byFunction[jobFunc].push({ label: job.title, value: job.id });
    }
    return {
      loading: false,
      data: Object.entries(byFunction).map(([label, options]) => ({
        options: options.sort((a, b) => a.label.localeCompare(b.label)),
        label,
      })),
    };
  }, [data, loading]);
}
