import { Link } from "react-router-dom";
import { useMemo } from "react";

import { Button, Spacing, Text } from "@otta/design";
import { useAuthorizations } from "@hire/providers/authorization";
import { Feature } from "@hire/schema";
import { Loading } from "@otta/shared-components";

export const JobsListEmptyState = () => {
  const { features, loading } = useAuthorizations([
    Feature.CreateJob,
    Feature.JobSlots,
  ]);

  const createJobsFeature = features.get(Feature.CreateJob);

  const { text, link, buttonCopy } = useMemo(() => {
    if (createJobsFeature?.granted) {
      return {
        text: (
          <Text>
            Once you&apos;ve created a job we&apos;ll review and approve it.
          </Text>
        ),
        buttonCopy: "Create a job",
        link: "./create",
      };
    }

    return {
      text: (
        <Text style={{ lineHeight: 1.5 }}>
          You can use an ATS integration to ensure that all of your published
          jobs appear. <br /> When you post jobs on your ATS they will appear
          here.
        </Text>
      ),
      buttonCopy: "Go to ATS Integration",
      link: "../settings/ats",
    };
  }, [createJobsFeature?.granted]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div data-testid="empty-state-wrapper">
      <Spacing size={3}>
        {text}
        <Button level="primary" as={Link} to={link} relative="path">
          {buttonCopy}
        </Button>
      </Spacing>
    </div>
  );
};
