import styled, { css, up } from "@xstyled/styled-components";

import { modularScale } from "@otta/design-tokens";
import { Button, Card, Spacing, Text } from "@otta/design";
import { Link } from "@hire/components/links/Link";

const BenefitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${modularScale(-10)};
  flex-basis: 100%;
`;

const BenefitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: md;

  ${up(
    "tablet",
    css`
      gap: xl;
      flex-direction: row;
    `
  )}
`;

export const CanIntegrate = ({
  atsName,
  atsAuthorizeUrl,
}: {
  atsName: string;
  atsAuthorizeUrl: string;
}): React.ReactElement => {
  return (
    <Card>
      <Spacing size={4}>
        <Spacing size={-2}>
          <Text size={1} bold align="center">
            Good news! Welcome to the Jungle is now compatible with {atsName}
          </Text>
          <Text align="center">Linking {atsName} is quick and easy</Text>
        </Spacing>
        <Button
          as="a"
          data-testid="authorize-button"
          type="submit"
          level="primary"
          href={atsAuthorizeUrl}
        >
          Get started
        </Button>
        <BenefitsWrapper>
          <BenefitWrapper>
            <Text bold>View and manage applications in {atsName}</Text>
            <Text size={-1}>
              Applications will appear in your ATS with all the relevant fields
              answered
            </Text>
          </BenefitWrapper>
          <BenefitWrapper>
            <Text bold>Automatically keep jobs up to date</Text>
            <Text size={-1}>
              The quickest and most reliable way to get your jobs published on{" "}
              Welcome to the Jungle
            </Text>
          </BenefitWrapper>
          <BenefitWrapper>
            <Text bold>Export candidates to {atsName}</Text>
            <Text size={-1}>
              Easily export candidates sourced on Welcome to the Jungle in just
              one click
            </Text>
          </BenefitWrapper>
        </BenefitsWrapper>
        <Spacing size={3}>
          If your ATS has changed,{" "}
          <Link newTab to="https://form.typeform.com/to/Lxg4Qa0T">
            complete this form to let us know
          </Link>
          .
          <Text size={-1}>
            Need help getting set up?{" "}
            <Link to="mailto:help@welcometothejungle.com">
              <Text as="span" size={-1} style={{ textDecoration: "underline" }}>
                Contact us
              </Text>
            </Link>
            .
          </Text>
        </Spacing>
      </Spacing>
    </Card>
  );
};
