import { useMutation } from "@apollo/client";
import { useCallback, useMemo } from "react";

import {
  CatalogueProductId,
  CreateJobSlotCheckoutSessionDocument,
} from "@hire/schema";
import { handleMutationError } from "@hire/errors";

export function useJobSlotStripeCheckoutLink(
  jobId: string,
  returnPath: string
): {
  callback: () => void;
  loading: boolean;
} {
  const [createSession, { loading }] = useMutation(
    CreateJobSlotCheckoutSessionDocument,
    {
      onError: handleMutationError,
    }
  );

  const callback = useCallback(async () => {
    const { data } = await createSession({
      variables: {
        productId: CatalogueProductId.JobSlotMonthly,
        jobIds: [jobId],
        returnPath,
      },
    });

    const link = data?.createJobSlotCheckoutSession?.url;

    if (link) {
      window.location.href = link;
    }
  }, [createSession, returnPath, jobId]);

  return useMemo(
    () => ({
      callback,
      loading,
    }),
    [callback, loading]
  );
}
