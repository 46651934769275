import { StringSchema } from "yup";
import { Link } from "react-router-dom";

import { CompaniesWithPreApprovalQuery as CompanyList } from "@hire/schema";

type Company = CompanyList["companies"][0];

export function extractHost(url: string): string | null {
  try {
    const withScheme = url.match(/^https?:\/\//) ? url : `https://${url}`;
    return new URL(withScheme).hostname.replace(/^www\./, "") ?? null;
  } catch (e) {
    return null;
  }
}

function findCompany(domain: string, data?: CompanyList): Company | null {
  const host = extractHost(domain);

  if (!host) {
    return null;
  }

  return (
    data?.companies.find(
      c =>
        c.websiteUrl &&
        c.websiteUrl.includes(host) &&
        extractHost(c.websiteUrl) === host
    ) ?? null
  );
}

/**
 * If you're feeling tempted to replace the nasty regex in here with Yup.url()
 * then beware, because Yup.url() requires a fully valid absolute URL, inc. https://
 * and we don't want to enforce that
 */
export function validateDomain(
  schema: StringSchema,
  data?: CompanyList
): StringSchema {
  return schema
    .matches(
      /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,12}\b([-a-zA-Z0-9@:%\\+.~#()?&//=]*)/i,
      "Enter a valid URL"
    )
    .test(
      "not-existing-company-domain",
      d => {
        const company = findCompany(d.value, data);

        return (
          <>
            This company is already on Welcome to the Jungle. Sign in{" "}
            <Link
              to={`/signup/${company?.urlSafeName}`}
              data-testid="existing-signup-link"
            >
              here
            </Link>
            .
          </>
        );
      },
      domain => !domain || !findCompany(domain, data)
    );
}
