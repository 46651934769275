import { x } from "@xstyled/styled-components";
import { Link, useParams } from "react-router-dom";

import { JobSlotsModal } from "../JobSlotsModal";

import { Button, Text } from "@otta/design";
import { Confetti } from "@hire/components/Confetti";

export const SuccessfulPayment = () => {
  const { companySlug, jobExternalId } = useParams<
    "companySlug" | "jobExternalId"
  >();

  return (
    <>
      <JobSlotsModal
        name="successful-payment"
        redirectOnClose={`/${companySlug}/jobs`}
      >
        <x.div display="flex" flexDirection="column" gap="lg">
          <Text size={2} bold>
            Success!
          </Text>

          <Text>
            Your payment was successful, and your job listing should be live any
            second now for candidates to view. Start attracting great talent
            today!
          </Text>

          <x.div display="grid" gridTemplateColumns="1fr 1fr" gap="lg">
            <Button
              level="primary"
              as={Link}
              to={`${
                import.meta.env.VITE_SEARCH_APP_HOST
              }/jobs/${jobExternalId}`}
            >
              View job
            </Button>
            <Button level="secondary" as={Link} to={`../${jobExternalId}/edit`}>
              Edit job
            </Button>
          </x.div>
        </x.div>
      </JobSlotsModal>
      <Confetti />
    </>
  );
};
